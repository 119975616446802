<template>
  <p class="ma-0 pa-0">
    Handcrafted with
    <span class="heart-beat">
      <HeartIcon />
    </span>
    &
    <span class="cup-shake">
      <CoffeeIcon />
    </span>
    & a
    <span class="cat-ball">
      <CatIcon />
    </span>
    in New York City
  </p>
</template>

<script>
import HeartIcon from '@/icons/HeartIcon'
import CoffeeIcon from '@/icons/CoffeeIcon'
import CatIcon from '@/icons/CatIcon'
export default {
  name: 'HCrafted',
  components: {
    HeartIcon,
    CoffeeIcon,
    CatIcon
  }
}
</script>

<style scoped lang="scss">
/* Icon Animations CSS */
.heart-beat {
  & svg {
    width: 16px;
    height: 16px;
    -webkit-animation: heart-beat 5s ease infinite;
    animation: heart-beat 5s ease infinite;
    color: #bd081c;
  }
}

@keyframes heart-beat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  5% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
  20% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  30% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  35% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  55% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes heart-beat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  5% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
  20% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  30% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  35% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  55% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* Icon Animations CSS */
.cup-shake {
  & svg {
    width: 16px;
    height: 16px;
    -webkit-animation: cup-shake 3s linear infinite;
    animation: cup-shake 3s linear infinite;
    /*color: #91949B;/*#533a29;*/ /*#1c0f0b #6F4E37;*/
  }
}

@keyframes cup-shake {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  10%,
  20% {
    -webkit-transform: scale(0.9) rotate(-8deg);
    transform: scale(0.9) rotate(-8deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: scale(1.25) rotate(8deg);
    transform: scale(1.25) rotate(8deg);
  }
  40%,
  60% {
    -webkit-transform: scale(1.25) rotate(-8deg);
    transform: scale(1.25) rotate(-8deg);
  }
  80%,
  100% {
    -webkit-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}

@-webkit-keyframes cup-shake {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  10%,
  20% {
    -webkit-transform: scale(0.9) rotate(-8deg);
    transform: scale(0.9) rotate(-8deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: scale(1.25) rotate(8deg);
    transform: scale(1.25) rotate(8deg);
  }
  40%,
  60% {
    -webkit-transform: scale(1.25) rotate(-8deg);
    transform: scale(1.25) rotate(-8deg);
  }
  80%,
  100% {
    -webkit-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}

.cat-ball {
  & svg {
    width: 16px;
    height: 16px;
    -webkit-animation: cat-ball 3s ease-in-out infinite;
    animation: cat-ball 3s ease-in-out infinite;
    /*color: #91949B;/*#533a29;*/ /*#1c0f0b #6F4E37;*/
    color: #91949b;
  }
}
@keyframes cat-ball {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -webkit-transform: scale(1) translate3d(0, 0, 0);
    transform: scale(1) translate3d(0, 0, 0);
  }
  20% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
    -webkit-transform: scale(1.2) translate3d(0, -7px, 0);
    transform: scale(1.2) translate3d(0, -7px, 0);
  }
  40% {
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
    -webkit-transform: scale(1) translate3d(0, 0, 0);
    transform: scale(1) translate3d(0, 0, 0);
  }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
    -webkit-transform: scale(1.2) translate3d(0, -5px, 0);
    transform: scale(1.2) translate3d(0, -5px, 0);
  }
  75% {
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
    -webkit-transform: scale(1) translate3d(0, 0, 0);
    transform: scale(1) translate3d(0, 0, 0);
  }
  85% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
    -webkit-transform: scale(1.2) translate3d(0, -2px, 0);
    transform: scale(1.2) translate3d(0, -2px, 0);
  }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: scale(1) translate3d(0, 0, 0);
    transform: scale(1) translate3d(0, 0, 0);
  }
}

@-webkit-keyframes cat-ball {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -webkit-transform: scale(1) translate3d(0, 0, 0);
    transform: scale(1) translate3d(0, 0, 0);
  }
  20% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
    -webkit-transform: scale(1.2) translate3d(0, -7px, 0);
    transform: scale(1.2) translate3d(0, -7px, 0);
  }
  40% {
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
    -webkit-transform: scale(1) translate3d(0, 0, 0);
    transform: scale(1) translate3d(0, 0, 0);
  }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
    -webkit-transform: scale(1.2) translate3d(0, -5px, 0);
    transform: scale(1.2) translate3d(0, -5px, 0);
  }
  75% {
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
    -webkit-transform: scale(1) translate3d(0, 0, 0);
    transform: scale(1) translate3d(0, 0, 0);
  }
  85% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
    -webkit-transform: scale(1.2) translate3d(0, -2px, 0);
    transform: scale(1.2) translate3d(0, -2px, 0);
  }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: scale(1) translate3d(0, 0, 0);
    transform: scale(1) translate3d(0, 0, 0);
  }
}
</style>
