<template>
  <div class="footer-logo-box d-flex flex-column">
    <HLogoCombinationMark class="logo mb-2" @click="onClick" />

    <v-btn-toggle
      borderless
      dense
      tile
      class="align-center justify-center"
      background-color="transparent"
    >
      <HBrandButton
        v-for="(brand, index) in brands"
        :key="index"
        :href="brand.href"
        :icon="brand.icon"
        :class="{ 'mr-2': index !== brands.length - 1 }"
      />
    </v-btn-toggle>
  </div>
</template>

<script>
export default {
  name: 'HFooterLogoBox',
  components: {
    HLogoCombinationMark: () =>
      import(
        /* webpackChunkName: "svgs" */ '@/components/svgs/HLogoCombinationMark'
      ),
    HBrandButton: () =>
      import(
        /* webpackChunkName: "footer" */ '@/components/footer/HBrandButton'
      )
  },
  data: () => ({
    brands: [
      {
        icon: 'brandTwitter',
        href: 'https://twitter.com/Hylalab'
      },
      {
        icon: 'brandLinkedin',
        href: 'https://linkedin.com/company/hylalab'
      },
      {
        icon: 'brandMedium',
        href: 'https://hylalab.medium.com/'
      }
    ]
  }),
  methods: {
    onClick() {
      this.$emit('click')
    }
  }
}
</script>

<style scoped lang="scss">
.footer-logo-box::v-deep {
  max-width: 96px;

  & .logo {
    cursor: pointer;
  }
}
</style>
