<template>
  <v-btn
    v-bind="$attrs"
    v-on="$listeners"
    x-small
    icon
    :ripple="false"
    class="h-brand-button"
  >
    <v-icon color="white">${{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'HBrandButton',
  props: {
    icon: {
      type: String
    }
  }
}
</script>

<style scoped lang="scss">
.h-brand-button::v-deep {
  &::before {
    background-color: transparent;
  }

  & .v-icon__component:hover {
    opacity: 0.75 !important;
  }
}
</style>
