<template>
  <ul class="d-flex justify-end footer-links text-capitalize">
    <li v-for="(link, index) in links" :key="index">
      <router-link :to="`/${link}`">{{ link }}</router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'HNavLinks',
  props: {
    links: {
      type: Array
    }
  }
}
</script>

<style scoped lang="scss"></style>
